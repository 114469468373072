import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Use useNavigate for programmatic navigation
import axios from 'axios';

const Navbar = () => {
    const [islogin, setislogin] = useState(JSON.parse(sessionStorage.getItem('islogin')));
    console.log(islogin);

    const handleLogout = async () => {
        try {
            const response = await axios.post('http://127.0.0.1:8000/logout/', {
                withCredentials: true, 
            });
            console.log(response.data.message); 

            // Clear frontend session
            sessionStorage.setItem('islogin', JSON.stringify(false));
            setislogin(sessionStorage.getItem('islogin'));
            sessionStorage.removeItem('username');
            window.location.href = "/login";

        } catch (error) {
            console.error('Logout failed:', error.response?.data || error.message);
        }
    };

    return (
        <nav style={styles.navbar}>
            <h1 style={styles.link}><Link to="/"></Link></h1>
            <div style={styles.links}>
                <Link to="/" style={styles.link}>Home</Link>
                {/* {islogin && <Link to="/resumescorer" style={styles.link}>Resume Scorer</Link>} */}
                <Link to="/portfolio" style={styles.link}>Portfolio</Link>
                {/* {islogin ? (
                     <Link to="#" onClick={handleLogout} style={styles.link}>
                     Logout
                 </Link>
                ) : (
                    <Link to="/login" style={styles.link}>Login</Link>
                )} */}
                {/* {!islogin && <Link to="/register" style={styles.link}>Register</Link>} */}
            </div>
        </nav>
    );
};

// Styles for the navbar
const styles = {
    navbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        background: '#001d36',
        //color: '#fff',
    },
    title: {
        margin: 0,
    },
    links: {
        display: 'flex',
        gap: '15px',
    },
    link: {
        color: '#fff',
        textDecoration: 'none',
    },
};

export default Navbar;
